import {useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header";
import {
    Box,
    Button,
    IconButton,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import koLocale from 'date-fns/locale/ko';
import ConfirmDialog from "../../components/ConfirmDialog";
import validatorjs from "validatorjs";
import dvr from "mobx-react-form/lib/validators/DVR";
import MobxReactForm from "mobx-react-form";
import TextInput from "../../components/TextInput";
import WarningDialog from "../../components/WarningDialog";
import api from "../../common/api";
import {dataFormat} from "../../utils/FormatUtils";
import YoutubeLogo from '../../images/youtube_logo.png';
import LinkIcon from '@mui/icons-material/Link';
import {CommonCodeValue, ConditionType, OptionType, UseType} from "../../common/types";
import imgDoc from "../../images/icons/file_doc.png";
import imgPpt from "../../images/icons/file_ppt.png";
import imgPdf from "../../images/icons/file_pdf.png";
import imgHwp from "../../images/icons/file_hwp.png";
import imgUnknown from "../../images/icons/file_unknown.png";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 1040,
        margin: '0 130px 50px 130px',
        '& .MuiTab-root': {
            width: 180,
            height: 52,
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #C8CACE',
            '&:after': {
                backgroundColor: theme.palette.secondary.main,
                borderBottom: '1px solid #C8CACE'
            }
        },
        '& .Mui-selected': {
            backgroundColor: '#fff',
            borderBottom: '1px solid #fff',
            zIndex: '1 !important',
            '&:after': {
                backgroundColor: '#fff !important',
                borderBottom: '1px solid #fff !important'
            }
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        },
        '& > :first-child': {
            position: 'relative',
            zIndex: 10,
        },
        '& > :nth-child(2) , > :nth-child(3)': {
            position: 'relative',
            zIndex: 0,
            top: '-1px'
        }
    },
    tabs: {
        '& .MuiTabs-flexContainer': {
            '& > button': {
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                zIndex: 0,
                overflow: 'visible',
                marginRight: 32,
                padding: '12px 10px 12px 20px',
                '&:after': {
                    position: 'absolute',
                    content: '""',
                    width: 44,
                    height: 52,
                    right: '-23px',
                    transform: 'skewX(20deg)',
                    borderRight: '1px solid #C8CACE',
                    borderTop: '1px solid #C8CACE',
                    borderTopRightRadius:  5
                },
            },
            '& > :nth-child(2)': {
                marginLeft: '-7px'
            },
        }
    },
    tabLabel: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            color: '#303030',
            fontSize: 14,
            fontWeight: 800,
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            marginRight: 7
        },
        '& > span': {
            backgroundColor: '#9FA5B6',
            borderRadius: 15,
            width: 55,
            height: 21,
            fontSize: 11,
            fontWeight: 'bold',
            color: '#fff',
            padding: 4
        }
    },
    tabLabelOpen: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            color: '#303030',
            fontSize: 14,
            fontWeight: 800,
            fontFamily: ['sans-serif', 'Source Sans Pro', 'Nanum Gothic'],
            marginRight: 7
        },
        '& > span': {
            backgroundColor: '#F48B8F',
            borderRadius: 15,
            width: 55,
            height: 21,
            fontSize: 11,
            fontWeight: 'bold',
            color: '#fff',
            padding: 4
        }
    },
    panel: {
        backgroundColor: '#FFF',
        border: '1px solid #C8CACE',
        minHeight: 650,
        padding: '55px 60px 50px 40px',
        borderRadius: 5,
        borderTopLeftRadius: 0,
        marginBottom: 30,
        '& .MuiOutlinedInput-root': {
            height: 40,
            '&:hover > ::placeholder': {
                opacity: 0.4,
                color: theme.palette.text.gray
            }
        }
    },
    dateField: {
        '& .MuiOutlinedInput-root': {
            maxWidth: 300,
            '& > ::placeholder': {
                color: 'grey !important',
                opacity:1
            }
        }
    },
    calendarPaper: {
        ...theme.common.calendarStyle
    },
    field: {
        display: 'flex',
        alignItems: 'top',
        marginBottom: 30,
        width: '100%',
        '& span': {
            color: theme.palette.text.red
        },
        '& > .MuiTypography-root': {
            fontWeight: 800,
            color: theme.palette.text.dark,
            marginRight: 30,
            minWidth: 80,
            textAlign: 'end',
            marginTop: 13
        },
        '& .MuiOutlinedInput-root > ::placeholder': {
            color: theme.palette.text.red,
            opacity:1
        },
        '& fieldset': {
            borderWidth: '1px !important'
        },
        '& > :nth-child(2)': {
            flex: 1
        },
        '& > :nth-child(3)': {
            marginLeft: 60
        }
    },
    contentOption: {
        '& .MuiOutlinedInput-root': {
            minHeight: 120,
            height: 'auto',
            alignItems: 'flex-start'
        }
    },
    videoPreview: {
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        margin: '-10px 0 20px 110px',
        padding: 40,
        '& > iframe': {
            alignSelf: 'center'
        },
        '& > div': {
            padding: '60px 80px 0 80px',
            '& > img': {
                width: 112,
                height: 25,
                marginBottom: 20
            },
            '& > :nth-child(2)': {
                fontSize: 18,
                lineHeight: '28px',
                color: theme.palette.text.dark,
                maxWidth: 440,
                marginBottom: 30,
                wordBreak: 'break-all',
                overflow: 'hidden'
            }
        }
    },
    urlField: {
        marginBottom: 25,
        '& .MuiSvgIcon-root': {
            transform: 'rotate(-45deg)',
            width: 24,
            height: 24,
            color: theme.palette.primary.main,
            marginRight: 5,
            marginBottom: '-7px'
        },
        '& > a': {
            textDecoration: 'none',
            borderBottom: '1px solid #1155CC',
            color: '#1155CC'
        }
    },
    imageBox: {
        display: "flex",
        flexWrap: 'wrap',
        columnGap: 13,
        rowGap: 13,
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        minHeight: 165,
        padding: 20,
        '& > button': {
            width: 170,
            height: 130,
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
            border: '1px solid #B4B9C3',
            fontWeight: 'bold',
            borderRadius: 0,
            flexDirection: 'column',
            '& > .MuiSvgIcon-root': {
                backgroundColor: '#F1F1F1',
                borderRadius: '50%',
                width: 35,
                height: 35,
                padding: 8,
                marginBottom: 10
            }
        },
    },
    previewBox: {
        '& figure': {
            width: 170,
            height: 130,
            margin: 0,
            display: 'flex',
            alignItems: 'flex-start',
            overflow: 'hidden',
            '& img': {
                backgroundColor: 'white',
                opacity: 1,
                objectFit: 'contain',
                width: 170,
                height: 130,
            },
            '& > p': {
                display: 'none',
                maxWidth: 120,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginLeft: 10
            },
            '& > .MuiIconButton-root': {
                display: 'none',
                position: 'absolute',
                color: '#fff',
                width: 30,
                height: 30, marginTop: 5,
                padding: 0,
                '& > svg': {
                    width: 18,
                    height: 18
                }
            },
            '&:hover': {
                backgroundColor: '#000',
                opacity: 0.9,
                '& img': {
                    opacity: 0.5
                },
                '& > p': {
                    display: 'block',
                    position: 'absolute',
                    color: '#fff'
                },
                '& > .MuiIconButton-root': {
                    display: 'block',
                    marginLeft: 135
                },
            }
        }
    },
    buttons: {
        '& > button': {
            boxShadow: 'none',
            height: 50,
            width: 190,
            fontWeight: 'bold',
        },
        '& > :not(:last-child)': {
            marginRight: 10,
            border: '1px solid #9FA5B6',
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        }
    },
    fileBox: {
        display: "flex",
        flexWrap: 'wrap',
        columnGap: 13,
        rowGap: 13,
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        minHeight: 24,
        //padding: 13,
        '& > button': {
            width: '100%',
            height: 'auto',
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
            border: '1px solid #B4B9C3',
            fontWeight: 'bold',
            borderRadius: 0,
            flexDirection: 'initial',
            alignItems: 'center',
            '& > .MuiSvgIcon-root': {
                backgroundColor: '#F1F1F1',
                borderRadius: '50%',
                width: 35,
                height: 35,
                padding: 8,
                marginRight: 10
            }
        },
    },
    previewIcon: {
        '& figure': {
            width: 360,
            height: 24,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            '& img': {
                opacity: 1,
                objectFit: 'contain',
                width: 20,
                height: 'auto',
            },
            '& > p': {
                maxWidth: 310,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginLeft: 10
            },
            '& > .MuiIconButton-root': {
                display: 'block',
                color: '#000',
                width: 30,
                height: 30, marginTop: 5,
                padding: 0,
                '& > svg': {
                    width: 18,
                    height: 18
                }
            },
            '&:hover': {
                '& > .MuiIconButton-root': {
                    background: 'none',
                }
            }
        }
    },
}));

const isUsed = (category, type) => {
    if (category.optionList.length > 0) {
        let key = category.optionList.filter((opt) => opt.optionType === type)[0]?.useStatus;
        if (key === undefined) return false;
        return key === UseType.used
    } else return false;
}

const isRequired = (category, type) => {
    if (category.optionList.length > 0) {
        let key = category.optionList.filter((opt) => opt.optionType === type)[0]?.required;
        if (key === undefined) return false;
        return key === ConditionType.required
    } else return false;
}

function TabPanel(props) {

    const { value, index, post, formId, onFormOk, onFormInvalid, setEmpty, update, category, ...other } = props;
    const classes = useStyles();
    const isOpen = value === index;

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [url, setUrl] = useState('');
    const [date, setDate] = useState(null);
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    const [previewUrl, setPreviewUrl] = useState(null);
    const [errors, setErrors] = useState(null);

    const form = new MobxReactForm({
        fields: [
            { name: 'title', value: title, rules: 'required' },
            isUsed(category, OptionType.eventDate) ? { name: 'eventDate', value: date, rules: isRequired(category, OptionType.eventDate) ? 'required|date' : 'date'} : {},
            isUsed(category, OptionType.content) ? { name: 'content', value: content, rules: isRequired(category, OptionType.content) ? 'required' : null} : {},
            isUsed(category, OptionType.URL) ? { name: 'url', value: url, rules: isRequired(category, OptionType.URL) ? 'required|url' : 'url'} : {},
            isUsed(category, OptionType.image) ? { name: 'images', value: images, rules: isRequired(category, OptionType.image) ? 'required' : null} : {},
            isUsed(category, OptionType.file) ? { name: 'files', value: files, rules: isRequired(category, OptionType.file) ? 'required' : null} : {}
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSubmit(form) {
                const values = form.values();
                Object.keys(values).map((key) => {
                    if (values[key] !== null && values[key] !== "") {
                        post[key] = values[key];
                    } else if (values[key] === undefined) {
                        post[key] = []
                    }
                });
                setErrors(null);
                setEmpty(form.isEmpty);
            },
            onError(form) {
                setErrors(form.errors());
                onFormInvalid();
                update();
            },
            onSuccess() {
                onFormOk();
                update();
            }
        }
    });

    const onChangeUrl = (value) => {
        if (value.includes("www.youtube.com") || value.includes("youtu.be")) {
            let match = value.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/);
            match && match[1].length==11 ? setPreviewUrl(match[1]) : setPreviewUrl(null);
        } else {
            setPreviewUrl(null);
        }
    }

    function openFileExplorer() {
        document.getElementById(`upload-file-${formId}`).click();
    }

    function openImgFileExplorer() {
        document.getElementById(`upload-image-${formId}`).click();
    }

    function selectFile(file) {
        if (file && file.size / 1024 / 1024 > 10) {
            alert('허용된 업로드 용량(10Mb 이하)을 초과했습니다')
        } else if (file && file['type'].split('/')[1].match(/^(jpeg|png|jpg|gif|bmp)$/)) {
            let array = Array.from(images);
            array.push(file);
            setImages(array);
        } else if (file && file['name'].substring(file['name'].lastIndexOf('.') + 1).match(/^(doc|docx|ppt|pptx|pdf|hwp)$/)) {
            let array = Array.from(files);
            array.push(file);
            setFiles(array);
        } else if (file && file['type'].split('/')[0] === 'image') {
            alert('png, jpg, jpeg, gif, bmp 이미지 파일만 첨부 가능합니다')
        }
    }

    const handleImageDelete = (index) => {
        let array = Array.from(images);
        array.splice(index, 1);
        setImages(array);
    }

    const handleFileDelete = (index) => {
        let array = Array.from(files);
        array.splice(index, 1);
        setFiles(array);
    }

    return (
        <div role="tabpanel" hidden={!isOpen} {...other}>
            <form  noValidate autoComplete="off"  className={classes.panel} id={formId} onSubmit={form.onSubmit}>
                <Box className={classes.field}>
                    <TextInput form={form}
                               name={'title'}
                               label={'제목'}
                               errors={errors}
                               maxLength={200}
                               onChange={(e) => setTitle(e.target.value)}
                    />
                    {isUsed(category, OptionType.eventDate) &&
                        <React.Fragment>
                            <Typography variant='h4'>
                                <span hidden={!form.$('eventDate').rules.includes('required')}>*</span>
                                &nbsp;이벤트 날짜
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={koLocale}>
                                <DatePicker mask={'____-__-__'}
                                            value={date}
                                            onChange={(value) => setDate(value)}
                                            inputFormat={"yyyy-MM-dd"}
                                            PaperProps={{ className: classes.calendarPaper}}
                                            PopperProps={{ placement: 'bottom-end'}}
                                            disableHighlightToday={true}
                                            showDaysOutsideCurrentMonth
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                           className={classes.dateField}
                                                           error = {errors?.['eventDate'] !== null && errors?.['eventDate'] !== undefined}
                                                           helperText={ errors !== null && errors?.['eventDate'] === "The  is not a valid date format." ?
                                                                   "잘못된 날짜 형식입니다" : null}
                                                />}
                                />
                            </LocalizationProvider>
                        </React.Fragment>
                    }

                </Box>
                {isUsed(category, OptionType.content) &&
                    <Box className={classes.field}>
                        <TextInput form={form}
                                   name={'content'}
                                   label={'본문'}
                                   multiline={true}
                                   className={classes.contentOption}
                                   errors={errors}
                                   maxLength={2000}
                                   onChange={(e) => setContent(e.target.value)}/>
                    </Box>
                }
                {isUsed(category, OptionType.URL) &&
                    <Box className={classes.field}>
                        <TextInput form={form}
                                   name={'url'}
                                   label={'URL'}
                                   errors={errors}
                                   maxLength={500}
                                   onChange={(e) => {
                                       onChangeUrl(e.target.value);
                                       setUrl(e.target.value);
                                   }}/>
                    </Box>
                }

                {/* YouTube 미리보기 영역 */}
                {previewUrl !==null &&
                    <Box display='flex' className={classes.videoPreview}>
                        <iframe
                            id={"video-frame"}
                            width="500"
                            height="280"
                            src={`https://www.youtube.com/embed/${previewUrl}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <Box display="flex" flexDirection='column'>
                            <img src={YoutubeLogo} alt={"Youtube-Logo"}/>
                            <Typography>{title}</Typography>
                            <Typography className={classes.urlField}>
                                <LinkIcon/><a href={url}>{url}</a>
                            </Typography>
                        </Box>
                    </Box>
                }

                {/*   이미지 업로드   */}
                {isUsed(category, OptionType.image) &&
                    <Box className={classes.field}>
                        <Typography variant='h4'>
                            <span hidden={form.$('images').rules !== 'required'}>*</span>
                            &nbsp;이미지 첨부</Typography>
                        <Box className={classes.imageBox}>
                            {images.length > 0 &&
                                images.map((image, idx) => (
                                    <Box key={'preview-' + idx} className={classes.previewBox}>
                                        <figure>
                                            <img className="preview" src={image.encodingSrc ?? URL.createObjectURL(image)} alt="image"/>
                                            <p>{image.originalName ?? image.name}</p>
                                            <IconButton onClick={() => handleImageDelete(idx)}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </figure>
                                    </Box>
                                ))
                            }
                            <input type="file" id={`upload-image-${formId}`} hidden accept="image/*"
                                   onChange={(e) => selectFile(e.target.files[0])}
                                   onClick={(event) => {
                                       event.target.value = null
                                   }}
                            />
                            <Button onClick={() => openImgFileExplorer()}
                                    style={(errors?.['images'] !== undefined && errors?.['images']!==null) ? {border:'1px solid #F44336'} : null}>
                                <AddIcon/>이미지 추가</Button>
                        </Box>
                    </Box>
                }

                {/*   파일 업로드   */}
                {isUsed(category, OptionType.file) &&
                    <Box className={classes.field}>
                        <Typography variant='h4'>
                            <span hidden={form.$('files').rules !== 'required'}>*</span>
                            &nbsp;파일 첨부</Typography>
                        <Box className={classes.fileBox}>
                            <input type="file" id={`upload-file-${formId}`} hidden accept=".doc, .docx, .ppt, .pptx, .pdf, .hwp"
                                   onChange={(e) => selectFile(e.target.files[0])}
                                   onClick={(event) => {
                                       event.target.value = null
                                   }}
                            />
                            <Button onClick={() => openFileExplorer()}
                                    style={(errors?.['files'] !== undefined && errors?.['files']!==null) ? {border:'1px solid #F44336'} : null}>
                                <AddIcon/>파일 추가</Button>
                            {files.length > 0 &&
                                files.map((file, idx) => (
                                    <Box key={'file-preview-' + idx} className={classes.previewIcon}>
                                        <figure>
                                            <img className="preview" src={file.name.substring(file.name.lastIndexOf('.') + 1).match(/^(doc|docx)$/) ? imgDoc :
                                                (file.name.substring(file.name.lastIndexOf('.') + 1).match(/^(ppt|pptx)$/) ? imgPpt :
                                                        (file.name.substring(file.name.lastIndexOf('.') + 1).match(/^(pdf)$/) ? imgPdf :
                                                                (file.name.substring(file.name.lastIndexOf('.') + 1).match(/^(hwp)$/) ? imgHwp :
                                                                        imgUnknown
                                                                )
                                                        )
                                                )} alt="file"/>
                                            <p>{file.originalName ?? file.name}</p>
                                            <IconButton onClick={() => handleFileDelete(idx)}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </figure>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                }

                <button id={'submit-' + formId} hidden onClick={form.onSubmit}>submit</button>
            </form>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function BoardCreatePage(props) {

    const classes = useStyles();
    const {state} = useLocation();
    const category = state.category;
    const history = useHistory();
    const [update, setUpdate] = useState(false);
    const {pageTitle, openPage} = props;

    const [isCompletedKo, setIsCompletedKo] = useState(false);
    const [isCompletedEn, setIsCompletedEn] = useState(false);
    const [isEmptyKo, setIsEmptyKo] = useState(true);
    const [isEmptyEn, setIsEmptyEn] = useState(true);

    const [openSave, setOpenSave] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);

    const koreanPost = useRef({
                language: 'CMMLNG001',
                title: "",
                eventDate: "",
                content: "",
                url: "",
                images: []
            });
    const englishPost = useRef({
                language: 'CMMLNG002',
                title: "",
                eventDate: "",
                content: "",
                url: "",
                images: []
            });
    const imgFiles = useRef([]);
    const uploadFiles = useRef([]);

    const [value, setValue] = useState(0);  // tab index
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function submitForms() {
        document.getElementById('submit-form-ko').click();
        document.getElementById('submit-form-en').click();
    }

    function saveBoard() {
        let boardGroupData = {
            visible: "",
            boardList: []
        };
        let boardList = [];

        isCompletedKo && boardList.push({
            language: koreanPost.current.language,
            title: koreanPost.current.title,
            eventDate: koreanPost.current.eventDate==='' ? null : dataFormat(koreanPost.current.eventDate, 'date'),
            url: koreanPost.current.url,
            content: koreanPost.current.content,
            imageStartIndex: koreanPost.current.images.length > 0 ? 0 : null,
            imageCount: koreanPost.current.images.length > 0 ? koreanPost.current.images.length : null,
            fileStartIndex: koreanPost.current.files.length > 0 ? 0 : null,
            fileCount: koreanPost.current.files.length > 0 ? koreanPost.current.files.length : null
        });
        isCompletedEn && boardList.push({
            language: englishPost.current.language,
            title: englishPost.current.title,
            eventDate: englishPost.current.eventDate==='' ? null : dataFormat(englishPost.current.eventDate, 'date'),
            url: englishPost.current.url,
            content: englishPost.current.content,
            imageStartIndex: englishPost.current.images.length > 0 ? 0 : null,
            imageCount: englishPost.current.images.length > 0 ? englishPost.current.images.length : null,
            fileStartIndex: englishPost.current.files.length > 0 ? 0 : null,
            fileCount: englishPost.current.files.length > 0 ? englishPost.current.files.length : null
        });

        boardGroupData.visible = 'CMMVSB002';
        boardGroupData.boardList = boardList;

        if (koreanPost.current.images.length > 0 || englishPost.current.images.length > 0) {
            isCompletedKo && koreanPost.current.images.map((img) => imgFiles.current.push(img))
            isCompletedEn && englishPost.current.images.map((img) => imgFiles.current.push(img))
        }

        if (koreanPost.current.files.length > 0 || englishPost.current.files.length > 0) {
            isCompletedKo && koreanPost.current.files.map((file) => uploadFiles.current.push(file))
            isCompletedEn && englishPost.current.files.map((file) => uploadFiles.current.push(file))
        }

        api.addBoardGroup(state.category.seq, imgFiles.current, uploadFiles.current, boardGroupData)
            .then(() => {
                openPage("list");
            })
            .catch((err) => {
                console.log(CommonCodeValue.getErrMsg(err.rtCode))
            })
        setOpenSave(false);
    }

    useEffect(() => {
        if ((isCompletedKo && isCompletedEn) || (isCompletedEn && isEmptyKo) || (isCompletedKo && isEmptyEn)) {
            setOpenSave(true)
        } else if (!isEmptyKo && !isCompletedKo && value === 1) {
            setValue(0)
        } else if (!isEmptyEn && !isCompletedEn && value === 0) {
            setValue(1)
        }
    }, [update]);

    return (
        <React.Fragment>
            <Header name={pageTitle} navMain={state.category.name} navSecond={pageTitle}
                    description={"FNSVALUE의 게시물을 추가 또는 수정할 수 있는 페이지입니다."}/>

            <Box className={classes.root}>
                <Box display='flex' alignItems='flex-end' justifyContent='space-between' zIndex={100}>
                    <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" className={classes.tabs}>
                        <Tab label={
                            <Box className={value === 0 ? classes.tabLabelOpen : classes.tabLabel}>
                                <Typography>한국어</Typography>
                                <span>{!isCompletedKo ? "미작성" : "작성"}</span>
                            </Box>}
                             disableRipple {...a11yProps(0)} />
                        <Tab label={
                            <Box className={value === 1 ? classes.tabLabelOpen : classes.tabLabel}>
                                <Typography>영어</Typography>
                                <span>{!isCompletedEn ? "미작성" : "작성"}</span>
                            </Box>}
                             disableRipple {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0} post={koreanPost.current} formId={"form-ko"}
                          category={category}
                          onFormOk={() => {
                              setIsCompletedKo(true);
                              setIsCompletedEn(false);
                          }}
                          onFormInvalid={() => setIsCompletedKo(false)}
                          setEmpty={(value) => setIsEmptyKo(value)}
                          update={() => setUpdate(prevState => !prevState)}
                />
                <TabPanel value={value} index={1} post={englishPost.current} formId={"form-en"}
                          category={category}
                          onFormOk={() => setIsCompletedEn(true)}
                          onFormInvalid={() => setIsCompletedEn(false)}
                          setEmpty={(value) => setIsEmptyEn(value)}
                          update={() => setUpdate(prevState => !prevState)}
                />

                <Box display='flex' justifyContent='flex-end' className={classes.buttons}>
                    <Button onClick={() => setOpenCancel(true)}>취소</Button>
                    <Button variant='contained' onClick={submitForms}>저장</Button>
                </Box>
            </Box>
            <ConfirmDialog open={openSave}
                           onClose={() => setOpenSave(false)}
                           onOk={saveBoard}
                           title={'저장 확인'}
                           content={'게시물 저장 하시겠습니까?'}/>
            <WarningDialog open={openCancel}
                           onClose={() => setOpenCancel(false)}
                           onOk={() => history.goBack()}
                           title={'취소 확인'}
                           content={'게시물 등록 취소 하시겠습니까?'}
            />
        </React.Fragment>
    )
}